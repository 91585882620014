:root {
  --card-border-radius: 20px;
  --card-padding: 3;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif;
  min-height: -webkit-fill-available;
  height: 100dvh;
  overflow: hidden;
  color: #fff;
}

#root,
.root {
  background-image: linear-gradient(45deg, rgb(19, 19, 17) 0%, transparent 73%), repeating-linear-gradient(90deg, rgba(127, 127, 127, 0.1) 0px, rgba(127, 127, 127, 0.1) 1px, transparent 1px, transparent 17px), repeating-linear-gradient(0deg, rgba(127, 127, 127, 0.1) 0px, rgba(127, 127, 127, 0.1) 1px, transparent 1px, transparent 17px), linear-gradient(0deg, rgb(19, 19, 17), rgb(19, 19, 17));
}

.logo {
  max-height: 40px;
  position: absolute;
  top: 50px;
  left: 50%;
  transform: translateX(-50%);
}


.beforeSubmit-wrapper input {
  background: rgba(0, 0, 0, 0.25);
  padding: calc(var(--card-padding) * 1%);
  border-radius: 5px;
  width: 100%;
  border: 1px solid rgba(220, 220, 255, 0.2);
  font-weight: 300;
  color: #fff;
}

#root .car-details-wrapper label {
  font-size: 16px;
  font-weight: 300;
}

.car-details-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.car-details-button.MuiButton-colorSecondary {
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
  background-image: repeating-linear-gradient(135deg, rgba(68, 68, 68, 0.07) 0px, rgba(68, 68, 68, 0.07) 2px, transparent 2px, transparent 4px), repeating-linear-gradient(45deg, rgba(68, 68, 68, 0.07) 0px, rgba(68, 68, 68, 0.07) 2px, transparent 2px, transparent 4px), linear-gradient(135deg, rgb(251, 168, 16), rgb(251, 222, 55));
}

#root .car-details-wrapper .cloudinary-button {
  background-color: #fefefe;
  color: #131311;
  border-radius: 5px;
  border: 0;
}













.desktop {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  font-size: 10px;
  vertical-align: middle;
  top: 55px;
  align-items: center;
  display: none;
  gap: 15px;
  font-weight: 700;
  white-space: nowrap;
}

.horizontalCard {
  max-width: 90%;
  z-index: 1;
  width: 80%;
  max-height: 90%;
  aspect-ratio: 2/1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: var(--card-border-radius);
  display: flex;
  overflow: hidden;
  padding: calc(var(--card-padding) * 1%);

  border: 1px solid rgba(43, 43, 43, 0.568);
  box-sizing: border-box;
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
  /* background-image: repeating-linear-gradient(0deg, hsla(127, 4%, 81%, 0.05) 0px, hsla(127, 4%, 81%, 0.05) 1px, transparent 1px, transparent 11px, hsla(127, 4%, 81%, 0.05) 11px, hsla(127, 4%, 81%, 0.05) 12px, transparent 12px, transparent 32px), repeating-linear-gradient(135deg, hsla(127, 4%, 81%, 0.05) 0px, hsla(127, 4%, 81%, 0.05) 1px, transparent 1px, transparent 11px, hsla(127, 4%, 81%, 0.05) 11px, hsla(127, 4%, 81%, 0.05) 12px, transparent 12px, transparent 32px), repeating-linear-gradient(0deg, hsla(127, 4%, 81%, 0.05) 0px, hsla(127, 4%, 81%, 0.05) 1px, transparent 1px, transparent 11px, hsla(127, 4%, 81%, 0.05) 11px, hsla(127, 4%, 81%, 0.05) 12px, transparent 12px, transparent 32px), repeating-linear-gradient(135deg, hsla(127, 4%, 81%, 0.05) 0px, hsla(127, 4%, 81%, 0.05) 1px, transparent 1px, transparent 11px, hsla(127, 4%, 81%, 0.05) 11px, hsla(127, 4%, 81%, 0.05) 12px, transparent 12px, transparent 32px), linear-gradient(90deg, rgb(176, 136, 0), rgb(157, 54, 1)); */
  background-image: repeating-linear-gradient(135deg, rgba(68, 68, 68, 0.07) 0px, rgba(68, 68, 68, 0.07) 2px, transparent 2px, transparent 4px), repeating-linear-gradient(45deg, rgba(68, 68, 68, 0.07) 0px, rgba(68, 68, 68, 0.07) 2px, transparent 2px, transparent 4px), linear-gradient(135deg, rgb(251, 168, 16), rgb(251, 222, 55));
}


.section-container {
  width: 100%;
}

*,
input,
label {
  color: #fff;
}

a {
  color: #fff;
  text-decoration: none;
}

.hidden {
  display: none !important;
}


.section {
  display: flex;
  flex-direction: column;
  align-items: self-start;
  padding-left: calc(var(--card-padding) * 2%);
  justify-content: space-between;
  height: 100%;
}

#root .section label {
  margin: calc((var(--card-padding) * 2%) - 4px);
}

#root .section label.radio-button {
  margin: 0px;
  font-size: 12px;
}

#root .section label.want-case {
  margin: 0;
}

#root .section label.want-case>span {
  padding-left: 0;
}

#root .section .MuiFormControlLabel-root .MuiTypography-body1 {
  font-size: 0.8rem !important;
}

#root .section .dateLocation {
  max-width: 80%;
}

#root .section .dateLocation input {
  height: 30px;
}

#root .section .dateLocation label {
  transform: translate(0, 12px) scale(1);
}

#root .section .dateLocation .MuiInputLabel-formControl.MuiInputLabel-shrink {
  transform: translate(0, -4px) scale(0.85);
}

.section input,
textarea {
  background: rgba(255, 255, 255, 0.05);
  padding: calc(var(--card-padding) * 2.5%) calc(var(--card-padding) * 1.5%) calc(var(--card-padding) * 1%);
  border-radius: 10px;
  color: #fff;
  font-weight: 300;
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
}

textarea {
  background: rgba(0, 0, 0, 0.2);
  width: calc(100% - (2 * (var(--card-padding) * 1%)));
  padding: calc(var(--card-padding) * 1%);
}

textarea::placeholder {
  color: rgba(255, 255, 255, 0.5);
  font-family: "Montserrat", sans-serif;
}

.section .subnotes {
  margin-top: 10px;
  text-align: left;
  align-self: self-start;
}

.section .subnotes small {
  font-size: 10px;
  font-weight: 200;
}

.section .MuiInputLabel-formControl {
  transform: translate(0, 4px) scale(1);
  opacity: 1;
}

.section .MuiInputLabel-formControl.MuiInputLabel-shrink {
  transform: translate(0, -8px) scale(0.85);
  opacity: 0.7;
}

.section .MuiFormControl-root {
  width: 100%;
}

.section .MuiGrid-container {
  max-width: 100%;
  align-self: center;
  margin-left: -20px;
}


.section .MuiFormGroup-root {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
}

.section .centeredGridItem {
  justify-content: center;
  display: flex;
}

.section-titles h1 {
  font-weight: 200;
}

.section-titles h1 strong {
  font-weight: 600;
}

.section-titles {
  display: flex;
  text-align: left;
  flex-direction: column;
  width: 100%;
}

.section-titles .headline {
  display: flex;
  align-items: center;
  max-width: 60%;
}

.section-titles .socials img {
  max-width: 30px;
  margin: 0 5px;
  cursor: pointer;
}

.section-titles .socials img:hover {
  opacity: 0.7;
}

.section-titles .titles {
  flex-direction: row;
  display: flex;
  align-items: center;
  font-family: "Libre Baskerville", serif;
  text-transform: uppercase;
  font-weight: 800;
  font-size: 14px;
  justify-content: space-between;
}

.section-titles img {
  max-height: 35px;
  margin-right: 10px;
  vertical-align: middle;
}

#root .MuiInput-root {
  width: 100%;
  display: flex;
  justify-content: center;
}


#root label+.MuiInput-formControl.MuiInputBase-root {
  margin: 0px;
}

.MuiInputBase-root.Mui-error {
  border: 1px solid orange;
  border-radius: 8px;
}

#root .MuiGrid-root.MuiGrid-item {
  padding: 5px;
}

.MuiPaper-root.MuiMenu-paper li {
  justify-content: center;
}

.MuiPaper-root.MuiMenu-paper {
  background: #131311;
  color: #fff;
}

#root .MuiSelect-select {
  padding-right: 0;
  max-width: 80px;
  height: 80px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  color: #fff;
  font-weight: 400;
  font-size: 18px;
  text-align: center;
  line-height: 80px;
}

.select-control {
  align-items: center;
}

#root .MuiInput-root.select-input {
  width: 80px;
}

#root .MuiFormLabel-root {
  color: #fff;
  font-size: 12px;
  font-weight: 300;
}

#root .MuiInput-underline:before,
#root .MuiInput-underline:after {
  border: 0;
}

.with-box-shadow {
  box-shadow:
    1px 2px 10px rgba(0, 0, 0, 0.1);
}

.section-divider {
  min-width: 30%;
  max-width: 30%;
  overflow: hidden;
  height: 100%;
  display: block;
  border-radius: calc(var(--card-border-radius) - ((var(--card-padding) * var(--card-border-radius)) / 100));
  position: relative;
}

.section-divider img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.section-divider video {
  max-width: 100%;
  aspect-ratio: 9 / 13;
}

.with-drop-shadow {
  filter: drop-shadow(1px 2px 4px rgba(0, 0, 0, 0.1));
}

.arrowButton,
.summary {
  position: absolute;
  bottom: 50px;
  align-items: center;
  display: flex;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  padding: 8px;
}

.summary.last-index {
  right: 10%;
}

.arrowButton svg {
  height: 20px;
}

.arrowButtonLeft {
  left: 10%;
}

.arrowButtonRight {
  right: 10%
}

.discount {
  font-size: 12px;
  font-weight: 500;
  padding: 10px;
  text-align: center;
}










.background {
  position: absolute;
  overflow: hidden;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(to right, #131311, #131311);
  display: flex;
  flex-grow: 1;
  z-index: -1;
}

.background div {
  position: absolute;
  border-radius: 100%;
  height: 0;
  filter: blur(240vw);
  opacity: 0.4;
}

.background div:nth-child(1) {
  background: linear-gradient(132deg, #5d502c 0.00%, #5d502c 100.00%);
  width: 68%;
  padding-top: 68%;
  left: 93%;
  top: 38%;
  transform: translateX(-50%) translateY(-50%);
}

.background div:nth-child(2) {
  background: linear-gradient(132deg, #374a68 0.00%, #313945 100.00%);
  width: 71%;
  padding-top: 71%;
  left: 53%;
  top: 66%;
  transform: translateX(-50%) translateY(-50%);
}

.background div:nth-child(3) {
  background: linear-gradient(132deg, #131311 0.00%, #131311 100.00%);
  width: 71%;
  padding-top: 71%;
  left: 58%;
  top: 0%;
  transform: translateX(-50%) translateY(-50%);
}

#root .summaryGridContainer {
  font-size: 11px;
  font-weight: 100;
  line-height: 18px;
  margin: 0;
  width: 100%;
}

.summaryGridContainer strong {
  font-size: 13px;
  font-weight: 700;
  min-width: 130px;
}

.summary-item {
  border-radius: 8px;
  background: rgba(220, 220, 255, 0.05);
  min-height: 40px;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  word-break: break-word;
}

.summary-item:hover {
  background: rgba(220, 220, 255, 0.2);
}

.summary-item.gdpr {
  background: rgba(220, 220, 255, 0.1);
}

.summaryGridContainer .summary-item-container {
  padding: 2px 5px;
}

.summaryGridContainer .summary-container {
  margin: 0;
}

.summary-item-container.total {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.summaryForm input {
  display: none;
}


.beforeSubmit-wrapper,
.beforeSubmit-backdrop {
  position: absolute;
  width: 100%;
  height: 100dvh;
  top: 0;
  left: 0;
  z-index: 999;
}

.beforeSubmit-backdrop {

  background: rgba(0, 0, 0, 0.9);
}

.beforeSubmit {
  position: absolute;
  width: 50%;
  aspect-ratio: 2/1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  padding: calc(var(--card-padding) * 1%);
  border-radius: 10px;
  border: 1px solid rgba(43, 43, 43, 0.568);
  box-sizing: border-box;
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
  background-image: repeating-linear-gradient(135deg, rgba(68, 68, 68, 0.07) 0px, rgba(68, 68, 68, 0.07) 2px, transparent 2px, transparent 4px), repeating-linear-gradient(45deg, rgba(68, 68, 68, 0.07) 0px, rgba(68, 68, 68, 0.07) 2px, transparent 2px, transparent 4px), linear-gradient(135deg, rgb(251, 168, 16), rgb(251, 222, 55));
}

.recaptcha {
  margin: 20px 0;
}

#root .send-that {
  margin-left: 15px;
}

.scroll-arrows {
  position: absolute;
  bottom: 5px;
  left: 65%;
  transform: translateX(-25%);
}





















.switch {
  --line: #555555;
  --dot: #F7F8FF;
  --circle: #bababa;
  --duration: .3s;
  --text: #bdbdbd;
  cursor: pointer;
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
}

.switch input {
  display: none;
}

.switch input+div {
  position: relative;
}

.switch input+div:before,
.switch input+div:after {
  --s: 1;
  content: "";
  position: absolute;
  height: 4px;
  top: 10px;
  width: 24px;
  background: var(--line);
  transform: scaleX(var(--s));
  transition: transform var(--duration) ease;
}

.switch input+div:before {
  --s: 0;
  left: 0;
  transform-origin: 0 50%;
  border-radius: 2px 0 0 2px;
}

.switch input+div:after {
  left: 28px;
  transform-origin: 100% 50%;
  border-radius: 0 2px 2px 0;
}

.switch input+div span {
  padding-left: 56px;
  line-height: 24px;
  color: var(--text);
}

.switch input+div span:before {
  --x: 0;
  --b: var(--circle);
  --s: 4px;
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  box-shadow: inset 0 0 0 var(--s) var(--b);
  transform: translateX(var(--x));
  transition: box-shadow var(--duration) ease, transform var(--duration) ease;
}

.switch input+div span:not(:empty) {
  padding-left: 64px;
}

.switch input:checked+div:before {
  --s: 1;
}

.switch input:checked+div:after {
  --s: 0;
}

.switch input:checked+div span:before {
  --x: 28px;
  --s: 12px;
  --b: var(--dot);
}













/* CSS pentru stilizarea modalului */
.modal {
  display: block;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Background întunecat */
  overflow: auto;
}

.modal-content {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border-radius: 10px;
  position: relative;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.section-divider {
  cursor: pointer;
}

.section-divider.with-box-shadow {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}











.geoLocationHeadsUp {
  opacity: 0.2;
  bottom: 60px;
  left: 50%;
  transform: translateX(-50%);
  width: 300px;
  font-size: 10px;
  z-index: 99999;
  position: absolute;
  text-align: center;
}





@media screen and (max-width: 1080px) {

  .section-titles h1 {
    font-size: 24px;
  }


}



@media screen and (max-width: 960px) {

  .section-titles h1 {
    font-size: 20px;
  }

}




@media screen and (max-width: 780px) {

  .logo {
    top: 20px;
  }


  .desktop {
    display: flex;
  }

  .horizontalCard {
    aspect-ratio: auto;
    height: 80dvh;
    max-height: 80dvh;
    flex-direction: column;
    padding: 0;
    width: 98%;
  }

  .section-divider {
    max-width: 100%;
    width: 100%;
    height: 100%;
    position: absolute;
  }


  .section-divider .switch {
    bottom: auto;
    top: 20px;
    z-index: 2;
  }

  .section-divider img {
    z-index: 0;

    opacity: 0.1;
  }

  .section-divider video {
    display: none;
  }

  .section-container {
    height: 100%;
    padding: calc(var(--card-padding) * 2%);
    width: auto;
  }

  .section {
    padding-top: 0px;
    padding-left: 0;
  }


  .arrowButton,
  .summary {
    bottom: 20px;
  }

  .summary {
    display: flex;
    align-items: center;
  }

  .summary.last-index {
    bottom: 12px;
    right: 5%;
  }

  .arrowButtonLeft {
    left: 5%;
  }

  .arrowButtonRight {
    right: 5%
  }

  .beforeSubmit {
    width: 90%;
    aspect-ratio: auto;
  }

  .scroll-arrows {
    left: 50%;
  }
}

@media screen and (max-height: 760px) and (min-width: 860px) {
  .logo {
    top: 25px
  }

  .arrowButton,
  .summary {
    bottom: auto;
    top: 50%;
  }

  .summary {
    bottom: 50px;
    top: auto;
  }

  .arrowButtonLeft {
    left: 10px;
  }

  .arrowButtonRight {
    right: 10px
  }
}