.logo,
.desktop {
	z-index: 99;
}

header.homepage {
	position: absolute;
}

/* Photo Banner */
.container {
	width: 100%;
	overflow: hidden;
	margin: 10px auto;
	opacity: 0.5;
	z-index: 0;
}

.photobanner,
.photobanner2 {
	height: 438px;
	width: 2130px;
	margin-bottom: 5px;
	font-size: 0;
}

.photobanner img,
.photobanner2 img {
	margin-bottom: 10px;
	margin-right: 5px;
	height: 438px;
	width: 350px;
	object-fit: cover;
}

.photobanner img {
	-webkit-transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	-ms-transition: all 0.5s ease;
	transition: all 0.5s ease;
}

.photobanner img:hover {
	transform: scale(1.2);
	cursor: pointer;
	box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
}


/*keyframe animations*/
.first {
	animation: bannermove 40s linear infinite;
}

@keyframes bannermove {
	0% {
		margin-left: 0px;
	}

	100% {
		margin-left: -355px;
	}
}

.second {
	animation: bannermoves 40s linear infinite;
}

@keyframes bannermoves {
	0% {
		margin-left: -355px;
	}

	100% {
		margin-left: 0px;
	}
}


:root {
	--glow-hue: 30deg;
	--shadow-hue: 30deg;
	--spring-easing: linear(0,
			0.002,
			0.01 0.9%,
			0.038 1.8%,
			0.156,
			0.312 5.8%,
			0.789 11.1%,
			1.015 14.2%,
			1.096,
			1.157,
			1.199,
			1.224 20.3%,
			1.231,
			1.231,
			1.226,
			1.214 24.6%,
			1.176 26.9%,
			1.057 32.6%,
			1.007 35.5%,
			0.984,
			0.968,
			0.956,
			0.949 42%,
			0.946 44.1%,
			0.95 46.5%,
			0.998 57.2%,
			1.007,
			1.011 63.3%,
			1.012 68.3%,
			0.998 84%,
			1);
	--spring-duration: 1.33s;
}

@property --shimmer {
	syntax: "<angle>";
	inherits: false;
	initial-value: 33deg;
}

@keyframes shimmer {
	0% {
		--shimmer: 0deg;
	}

	100% {
		--shimmer: 360deg;
	}
}

@keyframes shine {
	0% {
		opacity: 0;
	}

	15% {
		opacity: 1;
	}

	55% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}

@keyframes text {
	0% {
		background-position: 100% center;
	}

	100% {
		background-position: -100% center;
	}
}

.button-holder {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	z-index: 99;

}

.homepage button {
	color: var(--bg);
	font-weight: 600;
	/*     background-image: linear-gradient(90deg, #fcecfe, #fbf6e7, #e6fcf5); */
	background-image: linear-gradient(315deg,
			hwb(32 94% 0%) -10%,
			hwb(41 87% 0%) 50%,
			#fff0deea 110%);
	padding: 0.8em 1.4em;
	isolation: isolate;
	box-shadow: 0 2px 3px 1px hsl(var(--glow-hue) 50% 20% / 50%),
		inset 0 -10px 20px -10px hsla(var(--shadow-hue), 10%, 90%, 95%);
	border-radius: 0.66em;
	scale: 1;
	transition: all var(--spring-duration) var(--spring-easing);
	position: relative;
}

.homepage button:hover:not(:active),
.homepage button.active {
	transition-duration: calc(var(--spring-duration) * 0.5);
	scale: 1.2;
	box-shadow: 0 4px 8px -2px hsl(var(--glow-hue) 50% 20% / 50%),
		inset 0 0 0 transparent;
}

.homepage button:active {
	scale: 1.1;
	transition-duration: calc(var(--spring-duration) * 0.5);
}

.homepage .shimmer {
	position: absolute;
	inset: -40px;
	border-radius: inherit;
	mask-image: conic-gradient(from var(--shimmer, 0deg),
			transparent 0%,
			transparent 10%,
			black 36%,
			black 45%,
			transparent 50%,
			transparent 60%,
			black 85%,
			black 95%,
			transparent 100%);
	mask-size: cover;
	mix-blend-mode: plus-lighter;
	animation: shimmer 1s linear infinite both;
}

.homepage button:hover .shimmer::before,
.homepage button:hover .shimmer::after,
.homepage button.active .shimmer::before,
.homepage button.active .shimmer::after {
	opacity: 1;
	animation: shine 1.2s ease-in 1 forwards;
}

.homepage .shimmer::before,
.homepage .shimmer::after {
	transition: all 0.5s ease;
	opacity: 0;
	content: "";
	border-radius: inherit;
	position: absolute;
	mix-blend-mode: color;
	inset: 40px;
	pointer-events: none;
}

.homepage .shimmer::before {
	box-shadow: 0 0 3px 2px hsl(var(--glow-hue) 20% 95%),
		0 0 7px 4px hsl(var(--glow-hue) 20% 80%),
		0 0 13px 4px hsl(var(--glow-hue) 50% 70%),
		0 0 25px 5px hsl(var(--glow-hue) 100% 70%);
	z-index: -1;
}

.homepage .shimmer::after {
	box-shadow: inset 0 0 0 1px hsl(var(--glow-hue) 70% 95%),
		inset 0 0 2px 1px hsl(var(--glow-hue) 100% 80%),
		inset 0 0 5px 2px hsl(var(--glow-hue) 100% 70%);
	z-index: 2;
}

.homepage button .text {
	color: transparent;
	background-clip: text;
	background-color: var(--bg);
	background-image: linear-gradient(120deg,
			transparent,
			hsla(var(--glow-hue), 100%, 80%, 0.66) 40%,
			hsla(var(--glow-hue), 100%, 90%, 0.9) 50%,
			transparent 52%);
	background-repeat: no-repeat;
	background-size: 300% 300%;
	background-position: center 200%;
	color: #131313;
	font-size: 16px;
}

.homepage button:hover .text,
.homepage button.active .text {
	animation: text 0.66s ease-in 1 both;
}